/* eslint-disable no-useless-escape */
import { helpers, ValidationRule } from 'vuelidate/lib/validators'

export const phoneNumber: ValidationRule = helpers.regex(
  'phoneNumber',
  /^([\+]{1}628[1-35-9][0-9]{7,10})$/
)

export const double: ValidationRule = helpers.regex(
  'negativeDouble',
  /^[0-9]+\.[0-9]+$/
)

export const negativeDouble: ValidationRule = helpers.regex(
  'negativeDouble',
  /^\-?[0-9]+\.[0-9]+$/
)

export const semanticVersioning: ValidationRule = helpers.regex(
  'semanticVersioning',
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)?$/
)
